<template>
  <div>
    <v-card class="mx-auto" outlined>
      <ListSkeleton v-if="listSkeleton" />
      <v-card-text
        class="text-h5"
        :class="type ? 'px-2 pt-0' : 'pa-0'"
        v-else-if="quotationList.data"
      >
        <slot name="closeIcon"></slot>
        <v-data-table
          :dense="true"
          class="custom-table mb-0 ma-2 row-pointer"
          :headers="headers"
          :items="quotationList.data"
          :items-per-page="limit"
          :page.sync="page"
          :server-items-length="quotationList.meta.total"
          style="word-break: break-word"
          :options.sync="pagination"
          item-class="py-4"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50],
          }"
          :search="search"
          @dblclick:row="redirectOnEditPage"
          :sort-by.sync="sortByValue"
          :sort-desc.sync="sortDescValue"
          @update:sort-by="(val) => buildUrl(val, 'sortKey')"
          @update:sort-desc="(val) => buildUrl(val, 'sortDec')"
        >
          <template v-slot:top>
            <div class="text-body-2 primary--text mb-2">
              <v-row :dense="true">
                <v-col cols="2" class="text-end d-flex">
                  <v-autocomplete
                    v-if="!type"
                    hide-details="auto"
                    dense
                    v-model="selectedStatus"
                    :items="statusList"
                    item-text="name"
                    item-value="id"
                    flat
                    @input="buildUrl"
                    return-object
                    single-line
                    :label="$t('quotation.field.status')"
                  />
                </v-col>
                <v-col cols="3"> </v-col>
                <v-col cols="2" class="text-end d-flex">
                  <v-text-field
                    :full-width="false"
                    :dense="true"
                    hide-details="auto"
                    :autofocus="type ? true : false"
                    v-model="search"
                    @input="buildUrl"
                    append-icon="mdi-magnify"
                    :label="$t('common.search')"
                    single-line
                  />
                </v-col>
                <v-col cols="2">
                  <v-autocomplete
                    v-if="!type"
                    hide-details="auto"
                    dense
                    v-model="selectedCustomer"
                    :items="customerList.data"
                    item-text="name_1"
                    item-value="id"
                    clearable
                    flat
                    @input="buildUrl"
                    return-object
                    single-line
                    :search-input.sync="customerSearch"
                    :label="$t('quotation.field.customer')"
                  >
                    <template v-slot:append-item>
                      <div
                        v-show="hasNextPage"
                        v-intersect="infiniteScroll"
                        ref="load"
                        class="loader text-center"
                      >
                        <v-progress-circular indeterminate color="primary" />
                      </div>
                    </template>
                  </v-autocomplete>
                  <v-text-field
                    v-else
                    readonly
                    :dense="true"
                    hide-details="auto"
                    v-model="selectCustomerForDialog.name_1"
                    :label="$t('quotation.field.customer')"
                    single-line
                  />
                </v-col>
                <v-col cols="3" class="text-end d-flex">
                  <v-menu
                    ref="startDateMenu"
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="quotation_date"
                    transition="scale-transition"
                    offset-y
                    :nudge-left="40"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details="auto"
                        dense
                        class="me-2"
                        single-line
                        v-model="quotationDateFormat"
                        :label="$t('quotation.from')"
                        @click:clear="quotation_date = ''"
                        readonly
                        clearable
                        @input="buildUrl"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="quotation_date"
                      @input="buildUrl"
                      @change="$refs.startDateMenu.save(quotation_date)"
                    >
                      <v-btn
                        small
                        class="primary"
                        @click="$refs.startDateMenu.save(todayDate())"
                        >{{ $t("route.today") }}</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                  <v-menu
                    ref="endDateMenu"
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="quotation_validity"
                    transition="scale-transition"
                    offset-y
                    :nudge-left="160"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        hide-details="auto"
                        dense
                        single-line
                        v-model="quotationValideDateFormat"
                        :label="$t('quotation.to')"
                        @click:clear="quotation_validity = ''"
                        readonly
                        @input="buildUrl"
                        clearable
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :min="quotation_date ? quotation_date : ''"
                      v-model="quotation_validity"
                      @input="buildUrl"
                      @change="$refs.endDateMenu.save(quotation_validity)"
                    >
                      <v-btn
                        small
                        class="primary"
                        @click="$refs.endDateMenu.save(todayDate())"
                        >{{ $t("route.today") }}</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`item.grand_total`]="props">
            <span>{{ numberWithCommas(props.item.grand_total) }}</span>
          </template>
          <template v-slot:[`item.quotation_date`]="props">
            <span>{{
              props.item.quotation_date
                ? profileById.date_format
                  ? new Date(props.item.quotation_date)
                      .toISOString()
                      .substring(0, 10)
                  : formatDateDDMMYYYY(props.item.quotation_date)
                : ""
            }}</span>
          </template>
          <template v-slot:[`item.status`]="props">
            <v-chip :color="getColor(props.item.status)" small dark>
              {{ statusList.filter((x) => x.id == props.item.status)[0].name }}
            </v-chip>
          </template>
          <template v-slot:[`footer.prepend`]>
            <v-row class="d-flex">
              <v-col class="mx-auto" cols="12">
                <span class="pl-3 pt-2"
                  >{{ $t("common.numberOfTotalRows") }}
                  {{ quotationList.meta.total }}</span
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`footer.page-text`]>
            <v-row class="d-flex">
              <v-col class="mx-auto mr-16" cols="4">
                <v-container class="w-100">
                  <v-pagination
                    :total-visible="5"
                    v-model="page"
                    @input="buildUrl"
                    :length="quotationList.meta.last_page"
                  ></v-pagination>
                </v-container>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text class="text-caption text-center" v-else>
        {{ $t("common.noDataFound") }}</v-card-text
      >
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ListSkeleton from "@/components/skeleton/ListSkeleton";
import { formatDateDDMMYYYY, parseDateYYYYMMDD, todayDate } from "@/utils";
import { intersectionMixin } from "@/mixins/intersectionMixin";
import { searchMixin } from "@/mixins/searchMixin";

export default {
  name: "QuotationTable",
  props: ["listSkeleton", "type", "selectCustomerForDialog"],
  components: {
    ListSkeleton,
  },
  mixins: [intersectionMixin, searchMixin],
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      limit: 10,
      sortByValue: this.$route.query.order_by || null,
      sortDescValue: this.$route.query.sortDec ? true : null,
      customerPage: 1,
      customerLimit: 10,
      pagination: {
        page: Number(this.$route.query.page) || 1,
        itemsPerPage: 10,
        sortBy: [this.$route.query.order_by || "quotation_date"],
        sortDesc: this.$route.query.order_by
          ? this.$route.query.sortDec
            ? [true]
            : [false]
          : this.$route.query.sortDec
          ? [true]
          : [false],
      },
      selectedCustomer: {
        id: Number(this.$route.query.custId) || null,
      },
      search: this.$route.query.search || "",
      startDateMenu: false,
      endDateMenu: false,
      quotation_date:
        this.$route.query.startDate ||
        new Date(new Date().setMonth(new Date().getMonth() - 6))
          .toISOString()
          .substring(0, 10),
      quotation_validity:
        this.$route.query.endDate || new Date().toISOString().substring(0, 10),
      selectedStatus: {
        id:
          this.$route.query.status === "all"
            ? null
            : this.$route.query.status === "closed"
            ? 1
            : this.$route.query.status === "cancelled"
            ? 2
            : (this.$route.query.status === "open" && 0) || 0,
      },
    };
  },
  computed: {
    quotationDateFormat() {
      return this.profileById.date_format
        ? parseDateYYYYMMDD(this.quotation_date)
        : this.formatDateDDMMYYYY(this.quotation_date);
    },
    quotationValideDateFormat() {
      return this.profileById.date_format
        ? parseDateYYYYMMDD(this.quotation_validity)
        : this.formatDateDDMMYYYY(this.quotation_validity);
    },
    statusList() {
      return [
        { name: this.$t("quotation.field.all"), id: null },
        { name: this.$t("quotation.field.open"), id: 0 },
        { name: this.$t("quotation.field.finished"), id: 1 },
        { name: this.$t("quotation.field.canceled"), id: 2 },
      ];
    },
    params() {
      return {
        sortBy: this.pagination.sortBy,
        sortDesc: this.pagination.sortDesc,
        page: this.pagination.page,
        itemsPerPage: this.pagination.itemsPerPage,
        query: this.search,
        customer_id: this.selectedCustomer?.id,
        selectedStatus: this.selectedStatus?.id,
        quotation_date: this.quotation_date,
        quotation_validity: this.quotation_validity,
      };
    },
    ...mapGetters({
      profileById: "profile/profileById",
      quotationList: "quotation/quotationList",
      customerList: "customer/customerList",
      locale: "locale",
      accessRight: "accessRight",
    }),
    headers() {
      return [
        {
          text: this.$t("quotation.id"),
          align: "start",
          sortable: true,
          value: "quotation_number",
          width: this.type ? "80px" : "",
        },
        {
          text: this.$t("quotation.field.title"),
          value: "title",
          width: !this.type ? "260px" : "180px",
        },
        {
          text: this.$t("quotation.field.customer"),
          value: "customer.name_1",
          sortable: false,
          width: !this.type ? "260px" : "180px",
        },
        this.accessRight.includes("financial")
          ? {
              text: this.$t("quotation.field.grandTotal"),
              value: "grand_total",
              width: this.type ? "120px" : "",
            }
          : "",
        {
          text: this.$t("quotation.field.quotationStartDate"),
          value: "quotation_date",
          align: "center",
          width: this.type ? "160px" : "",
        },
        {
          text: this.$t("quotation.field.status"),
          value: "status",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    selectCustomerForDialog() {
      this.selectedCustomer = this.selectCustomerForDialog;
    },
    type: {
      handler() {
        this.search = "";
        this.page = 1;
        this.limit = 10;
        this.$emit("update:selectCustomerForDialog", null);
      },
      deep: true,
    },
    quotationDateFormat() {
      this.quotation_date = this.profileById.date_format
        ? this.quotationDateFormat
        : this.formatDateDDMMYYYY(this.quotationDateFormat);
    },
    quotationValideDateFormat() {
      this.quotation_validity = this.profileById.date_format
        ? this.quotationValideDateFormat
        : this.formatDateDDMMYYYY(this.quotationValideDateFormat);
    },
    customerLimit() {
      this.$store.dispatch("customer/GetCustomer", {
        page: 1,
        limit: this.customerLimit,
        order_by: "name_1|asc",
      });
    },
    params: {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.paginate(false);
      },
      deep: true,
    },
    "$route.query": {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal) !== JSON.stringify(oldVal) ||
          !Object.keys(this.$route.query).length
        )
          this.page = Number(this.$route.query.page) || 1;

        this.sortByValue = this.$route.query.order_by || null;

        this.sortDescValue = this.$route.query.sortDec ? true : null;

        this.selectedCustomer = {
          id: Number(this.$route.query.custId) || null,
        };

        this.search = this.$route.query.search || "";

        this.quotation_date =
          this.$route.query.startDate ||
          new Date(new Date().setMonth(new Date().getMonth() - 6))
            .toISOString()
            .substring(0, 10);

        this.quotation_validity =
          this.$route.query.endDate ||
          new Date().toISOString().substring(0, 10);

        this.selectedStatus = {
          id:
            this.$route.query.status === "all"
              ? null
              : this.$route.query.status === "closed"
              ? 1
              : this.$route.query.status === "cancelled"
              ? 2
              : (this.$route.query.status === "open" && 0) || 0,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    this.$emit("update:listSkeleton", true);
    this.$store.commit("quotation/SET_QUOTATION_QUERY", this.$route.query);
    if (Object.keys(this.$route.query).length) {
      this.paginate(false);
    } else {
      this.pagination.page = 1;
      this.pagination.itemsPerPage = 10;
      this.pagination.sortBy = ["quotation_date"];
      this.pagination.sortDesc = [true];
      if (this.type) {
        this.selectedCustomer = this.selectCustomerForDialog;
      }
    }
    this.$store.dispatch("profile/GetCompanyById");
    this.$store.dispatch("customer/GetCustomer", {
      page: this.customerPage,
      limit: this.customerLimit,
      order_by: "name_1|asc",
    });
  },
  methods: {
    parseDateYYYYMMDD: parseDateYYYYMMDD,
    formatDateDDMMYYYY: formatDateDDMMYYYY,
    todayDate: todayDate,
    numberWithCommas(x) {
      return x
        ? parseFloat(x)
            .toFixed(2)
            .toString()
            .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
        : (0).toFixed(2);
    },
    getColor(status) {
      if (status == 0) return "orange";
      else if (status == 1) return "green";
      else return "red";
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.customerLimit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async paginate(isSearch = false) {
      const {
        sortBy = this.pagination.sortBy,
        sortDesc = this.pagination.sortDesc,
        page = this.pagination.page,
        itemsPerPage = this.pagination.itemsPerPage,
        customer_id,
        selectedStatus,
        quotation_date,
        quotation_validity,
      } = this.params;

      // make params for where_and
      let concateWhereAnd = "";
      let whereAndVal = "|";
      concateWhereAnd += quotation_date ? "from_quotation_date," : "";
      concateWhereAnd += quotation_validity ? "to_quotation_date," : "";
      concateWhereAnd += selectedStatus || selectedStatus == 0 ? "status," : "";
      whereAndVal += quotation_date
        ? this.profileById.date_format
          ? quotation_date + ","
          : this.parseDateYYYYMMDD(quotation_date) + ","
        : "";
      whereAndVal += quotation_validity
        ? this.profileById.date_format
          ? this.parseDateYYYYMMDD(quotation_validity) + ","
          : quotation_validity + ","
        : "";
      whereAndVal +=
        selectedStatus || selectedStatus == 0 ? selectedStatus + "," : "";
      // end make params for where_and
      await this.$store
        .dispatch("quotation/GetQuotation", {
          page: isSearch ? 1 : page,
          limit: itemsPerPage,
          where: customer_id ? "customer_id|" + customer_id : customer_id,
          where_and:
            concateWhereAnd.slice(0, -1) && whereAndVal.slice(0, -1)
              ? concateWhereAnd.slice(0, -1) + whereAndVal.slice(0, -1)
              : undefined,
          where_like: this.search
            ? sortBy[0]
              ? sortBy[0] + "|" + this.search
              : sortBy[1]
              ? sortBy[1] + "|" + this.search
              : sortBy[2]
              ? sortBy[2] + "|" + this.search
              : sortBy[3]
              ? sortBy[3] + "|" + this.search
              : "id,title,grand_total,quotation_date|" + this.search
            : "id,title,grand_total,quotation_date|",
          order_by: sortBy?.[0]
            ? sortBy?.[0] + ",id|" + (sortDesc?.[0] ? "desc" : "asc") + ",desc"
            : "quotation_date,id|" + (sortDesc?.[0] ? "asc" : "desc") + ",desc",
        })
        .then(() => this.$emit("update:listSkeleton", false));
    },
    buildUrl(val) {
      if (!this.type) {
        let obj = {};
        if (typeof val === "string" || typeof val === "object") {
          this.page = 1;
          this.params.page = 1;
          obj.page = 1;
        }
        obj.search = this.search;
        obj.page = this.page;
        obj.status = this.selectedStatus?.id;
        obj.custId = this.selectedCustomer?.id;
        obj.startDate = this.quotation_date;
        obj.endDate = this.quotation_validity;
        obj.order_by = this.sortByValue;
        obj.sortDec = this.sortDescValue;
        obj = Object.entries(obj).reduce((acc, [key, val]) => {
          if (key === "custId" && val === null) return acc;
          else if (key === "page" && val === 1) return acc;
          else if (key === "startDate" && !val) return acc;
          else if (key === "endDate" && !val) return acc;
          else if (key === "status") {
            if (val === null) {
              return { ...acc, [key]: "all" };
            } else if (val === 1) {
              return { ...acc, [key]: "closed" };
            } else if (val === 2) {
              return { ...acc, [key]: "cancelled" };
            } else if (val === 0) {
              return { ...acc, [key]: "open" };
            } else return acc;
          } else if (key !== "status" && !val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router
          .replace({
            name: "Quotation",
            query: {
              ...obj,
            },
          })
          .catch(() => {});
        this.$store.commit("quotation/SET_QUOTATION_QUERY", obj);
      }
    },
    redirectOnEditPage(event, { item }) {
      console.log("this.type: ", this.type);
      !this.type
        ? this.accessRight.includes("edit") || this.accessRight.includes("show")
          ? this.$emit("redirectOnEditPage", item)
          : ""
        : this.$emit("selectCustomerQuotation", item);
    },
  },
};
</script>

<style scoped>
::v-deep .v-pagination__item {
  font-size: 12px;
}
</style>
