<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("quotation.quotationList") }}</span
        >
        <CommonAdd
          v-if="accessRight.includes('create')"
          :name="$t('quotation.quotation')"
          pathName="addQuotation"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <QuotationTable
          :listSkeleton.sync="listSkeleton"
          @redirectOnEditPage="redirectOnEditPage"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import QuotationTable from "./QuotationTable";
import CommonAdd from "@/components/CommonAdd";

export default {
  name: "Quotation",
  components: {
    QuotationTable,
    CommonAdd,
  },
  data() {
    return {
      listSkeleton: false,
    };
  },
  computed: {
    ...mapGetters(["accessRight"]),
  },
  methods: {
    redirectOnEditPage(item) {
      const path = "quotation/quotationAction/" + item.id;
      const windowName = "quotationTab_" + item.id;
      window.open(path, windowName);
    },
  },
};
</script>
