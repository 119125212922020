<template>
  <div>
    <div v-if="this.$route.name === 'Quotation'">
      <Quotation />
    </div>
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import Quotation from "@/views/companyApp/quotation/Quotation.vue";

export default {
  name: 'QuotationParent',
  components: {
    Quotation
  }
}
</script>
